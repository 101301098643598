import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react'; // Import useEffect for side effects
import { toast } from 'react-toastify';

console.log("o")
const PrivateRoutes = () => {
    const token = useSelector((state) => state.user.token);
    console.log("ajex", token)
    // Add useEffect to trigger toast when token changes
    useEffect(() => {
        if (!token) {
            toast.error("You have to login first", {
                position: "top-center",
                autoClose: 2000,
                theme: "colored"
            });
        }
    }, [token]); // Dependency array ensures useEffect runs when token changes

    return token ? <Outlet/> : <Navigate to="/" replace />; // Use replace to prevent adding a new entry to history
};

export default PrivateRoutes;
